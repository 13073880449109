import React from 'react';

import ContactIcons from './ContactIcons';
import './Footer.css';
import SnUrl from '../../images/sn-logo.svg';

const Footer = () => (
    <div style={{ backgroundColor: '#343a40', color: 'white' }}>
    <div className="footer-container">
        <div className="footer-item">
            <h5 className="footer-item-heading">
                About
            </h5>
            <div className="footer-about-container">
                <img src={SnUrl} alt="SN" style={{ width: '80px', height: '80px ' }} />
                <ul className="footer-about-description">
                    <li>Son Nguyen is a student from NUS, Singapore.</li>
                    <li>
                      He is a very competitively strong back-end engineer,
                      and very knowledgeable about web app development.
                    </li>
                    <li>However, he still has a lot to learn about design.</li>
                </ul>
            </div>
        </div>
        <div className="footer-item" />
        <div className="footer-item">
            <h5 className="footer-item-heading">
                Contact
            </h5>
            <p className="footer-about-container footer-about-description">
                If you have any enquiries, or just want to say hello,
                 feel free to contact me.
            </p>
            <div style={{ display: 'flex' }}>
                <ContactIcons noGutters placement="top" blockId={2} />
            </div>
        </div>
    </div>
    </div>
);

export default Footer;
