import React, { PureComponent } from 'react';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
} from 'reactstrap';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import ContactIcons from './ContactIcons';
import './NavbarHeader.css';


const headers = [
    {
        name: 'Home',
        pathname: '/',
    },
    {
        name: 'Resume',
        pathname: '/resume',
    },
    {
        name: 'Portfolio',
        pathname: '/portfolio',
    },
];

const NavItemLink = ({ children, to }) => (
    <Link
        className="nav-link"
        style={{ textDecoration: 'none' }}
        to={to}
    >
        {children}
    </Link>
);

NavItemLink.propTypes = {
    children: PropTypes.node.isRequired,
    to: PropTypes.string.isRequired,
};

class NavbarHeader extends PureComponent {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false,
        };
    }

    toggle() {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen,
        }));
    }

    render() {
        const { isOpen } = this.state;
        return (
            <div style={{ backgroundColor: '#343a40' }}>
                <Navbar color="dark" dark expand="sm">
                    <NavbarBrand href="/">SON NGUYEN</NavbarBrand>
                    <NavbarToggler onClick={this.toggle} />
                    <Collapse className="text-center" isOpen={isOpen} navbar>
                        <Nav className="ml-auto" navbar>
                            {headers.map(header => (
                                <NavItem
                                    key={header.name}
                                >
                                    <NavItemLink to={header.pathname}>
                                        {header.name}
                                    </NavItemLink>
                                </NavItem>
                            ))}
                            <NavItem>
                                <ContactIcons placement="bottom" blockId={1} />
                            </NavItem>
                        </Nav>
                    </Collapse>
                </Navbar>
            </div>
        );
    }
}

export default NavbarHeader;
