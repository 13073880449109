import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

import HomepageHeaderText from '../components/homepage/HomepageHeaderText';
import HomepageIntroSection from '../components/homepage/HomepageIntroSection';

const IndexPage = () => (
    <div style={{ backgroundColor: '#D0F4EA' }}>
      <Layout>
        <SEO title="Son Nguyen" keywords={['snguyenthanh', 'Son', 'Son Nguyen']} />
        <HomepageHeaderText />
        <HomepageIntroSection />
      </Layout>
    </div>
);

export default IndexPage;
