import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import LazyFadeContainer from '../LazyFadeContainer';
import HomepageHeaderDoubleLine from './HomepageHeaderDoubleLine';
import ReferencePanel from '../common/ReferencePanel';

const homepageIntroSectionQuery = graphql`
  query {
      resumeThumbnail: file(relativePath: { eq: "clement-h-544786-unsplash.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 576) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
      }
      portfolioThumbnail: file(relativePath: { eq: "classbuzz_0.png" }) {
          childImageSharp {
            fluid(maxWidth: 576) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
      }
  }
`;

const HomepageIntroSection = () => (
    <div style={{ backgroundColor: '#e8fcc2', paddingTop: '50px' }}>
        <HomepageHeaderDoubleLine />
        <StaticQuery
            query={homepageIntroSectionQuery}
            render={data => (
                <div style={{ paddingBottom: '20px' }}>
                    <LazyFadeContainer>
                        <ReferencePanel
                            to="/resume"
                            img={data.resumeThumbnail.childImageSharp.fluid}
                            title="Resume"
                            description="Find out what I've accomplished over the years."
                        />
                    </LazyFadeContainer>
                    <LazyFadeContainer>
                        <ReferencePanel
                            to="/portfolio"
                            img={data.portfolioThumbnail.childImageSharp.fluid}
                            title="Portfolio"
                            description="Some of the projects I've done so far."
                        />
                    </LazyFadeContainer>
                </div>
            )}
        />
    </div>
);

export default HomepageIntroSection;
