import React from 'react';
import { Link as BaseLink } from 'gatsby';
import PropTypes from 'prop-types';

if (typeof window !== 'undefined') {
    window.__navigatingToLink = false;
}

const Link = ({ children, ...props }) => (
    <BaseLink onClick={() => { window.__navigatingToLink = true; }} {...props}>
        {children}
    </BaseLink>
);

Link.propTypes = {
    children: PropTypes.node,
};

Link.defaultProps = {
    children: null,
};

export default Link;
