import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import TooltipIcon from './TooltipIcon';

const styles = {
    iconStyle: {
        textDecoration: 'none',
        fontSize: '30px',
    },
};

const iconHeaders = [
    {
        name: 'Email',
        icon: faEnvelope,
        isEmail: true,
        email: 'thanhson16198@gmail.com',
        tooltipText: 'Copy my email address to clipboard!',
    },
    {
        name: 'Github',
        icon: faGithub,
        pathname: 'https://github.com/snguyenthanh',
    },
    {
        name: 'Linkedin',
        icon: faLinkedin,
        pathname: 'https://www.linkedin.com/in/snguyenthanh/',
    },
];

const IconLink = ({ to, icon, noGutters, email, blockId, placement, size }) => {
    if (email !== '') {
        return (
            <a
                className="nav-link"
                style={{
                    ...styles.iconStyle,
                    margin: noGutters ? 0 : 'auto',
                }}
                rel="noopener noreferrer"
            >
                <TooltipIcon
                    placement={placement}
                    id={'email-tooltip'.concat(blockId)}
                    tooltipText={email}
                    delay={{
                        show: 0,
                        hide: 2500,
                    }}
                >
                    <FontAwesomeIcon icon={icon} size={size} />
                </TooltipIcon>
            </a>
        );
    }
    return (
        <a
            className="nav-link"
            href={to}
            style={{
                ...styles.iconStyle,
                margin: noGutters ? 0 : 'auto',
            }}
            target="_blank"
            rel="noopener noreferrer"
        >
            <FontAwesomeIcon icon={icon} size={size} />
        </a>
    );
};

IconLink.propTypes = {
    to: PropTypes.string,
    icon: PropTypes.object.isRequired,
    noGutters: PropTypes.bool,
    email: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
    blockId: PropTypes.number.isRequired,
    placement: PropTypes.string.isRequired,
};

IconLink.defaultProps = {
    to: '',
    noGutters: false,
};

const ContactIcons = ({ noGutters, blockId, placement, size }) => (
    <Fragment>
        {iconHeaders.map(iconHeader => (
            <IconLink
                key={iconHeader.name.concat(blockId)}
                icon={iconHeader.icon}
                to={iconHeader.pathname}
                noGutters={noGutters}
                email={iconHeader.isEmail ? iconHeader.email : ''}
                blockId={blockId}
                placement={placement}
                size={size}
            />
        ))}
    </Fragment>
);

ContactIcons.propTypes = {
    noGutters: PropTypes.bool,
    blockId: PropTypes.number.isRequired,
    placement: PropTypes.string.isRequired,
    size: PropTypes.string,
};

ContactIcons.defaultProps = {
    noGutters: false,
    size: '1x',
};
export default ContactIcons;
