import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';

import Link from './Link';
import './ReferencePanel.css';
import '../../css/UnderlineLink.scss';

const ReferencePanel = ({ children, to, img, title, description }) => (
    <Container className="reference-panel-section-container">
        <Row noGutters>
            <Col xs="12" sm="5">
                <Link to={to} style={{ textDecoration: 'none' }}>
                    <Img fluid={img} className="reference-panel-img" />
                </Link>
            </Col>
            <Col xs="12" sm="7">
                <div className="reference-panel-text-container">
                    <Link to={to} style={{ textDecoration: 'none' }}>
                        <h4 className="reference-panel-text-title underline-link">
                            {title}
                        </h4>
                    </Link>
                    <p className="lead reference-panel-text-description">
                        {description}
                    </p>
                    {children}
                </div>
            </Col>
        </Row>
    </Container>
);

ReferencePanel.propTypes = {
    children: PropTypes.node,
    to: PropTypes.string.isRequired,
    img: PropTypes.any.isRequired,
    title: PropTypes.string,
    description: PropTypes.string,
};

ReferencePanel.defaultProps = {
    children: null,
    title: '',
    description: '',
};

export default ReferencePanel;
