import React from 'react';
import PropTypes from 'prop-types';

import NavbarHeader from './common/NavbarHeader';
import Footer from './common/Footer';

import './layout.css';
import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrap 4.2.1
import '../css/bttn.min.css';
import '../css/fonts.css';
import '../css/UnderlineLink.scss';

const Layout = ({ children }) => (
    <div>
        <NavbarHeader />
        {children}
        <Footer />
    </div>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
