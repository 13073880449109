import React from 'react';
import LazyLoad from 'react-lazyload';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';

const LazyFadeContainer = ({ children, duration }) => (
    <LazyLoad height="100%" once>
        <Fade ssrFadeout duration={duration} exit={false}>
              {children}
        </Fade>
    </LazyLoad>
);

LazyFadeContainer.propTypes = {
    children: PropTypes.node.isRequired,
    duration: PropTypes.number,
};

LazyFadeContainer.defaultProps = {
    duration: 500,
};

export default LazyFadeContainer;
