import React from 'react';
import './HomepageHeaderDoubleLine.css';

const HomepageHeaderDoubleLine = () => (
    <p className="double-line">
        <strong>Here&apos;s what I&apos;ve done</strong>
    </p>
);

export default HomepageHeaderDoubleLine;
