import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'reactstrap';

import './TooltipIcon.css';

class TooltipIcon extends PureComponent {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            tooltipOpen: false,
        };
  }

  toggle() {
    this.setState(prevState => ({
        tooltipOpen: !prevState.tooltipOpen,
    }));
  }

    render() {
        const { children, placement, id, tooltipText, delay } = this.props;
        const { tooltipOpen } = this.state;
        return (
            <span>
                <div id={id}>
                    {children}
                </div>
                <Tooltip
                    placement={placement}
                    isOpen={tooltipOpen}
                    target={id}
                    toggle={this.toggle}
                    delay={delay}
                >
                    {tooltipText}
                </Tooltip>
            </span>
        );
    }
}

TooltipIcon.propTypes = {
    children: PropTypes.node.isRequired,
    placement: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    tooltipText: PropTypes.string.isRequired,
    delay: PropTypes.oneOfType([
        PropTypes.shape({ show: PropTypes.number, hide: PropTypes.number }),
        PropTypes.number,
    ]),
};

TooltipIcon.defaultProps = {
    delay: {
        show: 0,
        hide: 250,
    },
};

export default TooltipIcon;
